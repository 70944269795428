<template>
  <div @click="clicked">
    <v-runtime-template v-if="widgets" :template="widgets" />
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";

export default {
  name: "PostSidebarWidgets",
  mixins: [clickHandler],
  data() {
    return {
      widgets: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog
  },
  async mounted() {
    const postSidebarData = await CMService.getUxBlock("posts-sidebar");
    if (postSidebarData) {
      this.widgets = postSidebarData[0].content.rendered;
    }
  }
};
</script>
