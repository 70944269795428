<template>
  <v-container fluid class="blog-list-container">
    <!-- TODO - BREADCRUMB  -->
    <v-row>
      <v-col cols="12" md="9">
        <h1 class="mb-8">{{ $t("article.title") }}</h1>
        <div v-if="content != null">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              v-for="posts in content"
              :key="posts.id"
            >
              <ArticleCard :posts="posts"></ArticleCard>
            </v-col>
          </v-row>
        </div>
        <div v-if="noArticleToShow">
          {{ $t("article.noArticleToShow") }}
        </div>
        <v-pagination
          class="mt-12"
          color="primary"
          v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
          :value="pageFilter"
          :page="pager.selPage"
          :length="pager.totPages ? pager.totPages : 0"
          :totalVisible="6"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="3"><PostSidebar></PostSidebar></v-col>
    </v-row>
    <v-row>
      <v-col>
        <PostFooter></PostFooter>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.blog-list-container {
  .v-card__title {
    min-height: 60px;
  }
  .v-card__subtitle {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .share-btn {
    cursor: pointer;
  }
  a[class^="share-network"] {
    text-decoration: none;
  }
}
</style>
<script>
import Vue from "vue";
import categoryMixins from "~/mixins/category";
import clickHandler from "~/mixins/clickHandler";
import CMService from "~/service/cmService";
import socialSharing from "vue-social-sharing";
import { mapGetters } from "vuex";
import ArticleCard from "@/components/wordpress/ArticleCard.vue";
import PostSidebar from "@/components/wordpress/PostSidebar.vue";
import PostFooter from "@/components/wordpress/PostFooter.vue";
import get from "lodash/get";
export default {
  name: "ArticleList",
  mixins: [categoryMixins, clickHandler],
  components: {
    ArticleCard,
    PostSidebar,
    PostFooter
  },
  props: {
    s: {
      type: String,
      default: null
    },
    cat: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      content: null,
      newFilters: false,
      pager: {},
      open: false,
      parentCategories: null,
      noArticleToShow: false
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    async fetchPage(pageNum) {
      let params = [];
      this.page = pageNum || this.pageFilter;
      console.log("pageParams", this.page);
      this.pager.selPage = this.page;
      params.push({
        filterName: "page",
        filterValue: this.page
      });
      if (this.s != null && this.s.length > 0) {
        params.push({
          filterName: "filter[s]",
          filterValue: this.s
        });
      }
      console.log(this.cat);
      if (this.cat != null && this.cat.length > 0) {
        params.push({
          filterName: "categories",
          filterValue: this.cat
        });
      }
      const res = await CMService.getCustomPostByFilters("posts", params, 12);
      const categories = await CMService.getCustomPostByFilters(
        "categories",
        null,
        50
      );
      this.parentCategories = categories.data.filter(cat => cat.parent === 0);

      if (res && res.data && res.data.length) {
        this.pager = res.page;
        this.content = res.data;
      } else {
        this.content = null;
      }
      console.log(res);
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      this.fetchPage(page);
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },
    doPrint() {
      window.print();
    }
  },
  mounted() {
    Vue.use(socialSharing);
    this.fetchPage();
  },
  watch: {
    s() {
      let vm = this;
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    },
    cat() {
      let vm = this;
      if (this.newFilters == false) {
        this.newFilters = true;
        setTimeout(function() {
          vm.newFilters = false;
          vm.fetchPage(1);
        }, 300);
      }
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    return {
      title: "Magazine",
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.page, "meta.seo_description")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.page,
            "meta.seo_title",
            global.config?.defaultPageName
          )
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          property: "og:image:alt",
          content: get(this.page, "meta.seo_description")
        },
        {
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(this.page, "meta.seo_title", "Pagina statica")
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
