<template>
  <div>
    <h3 class="mt-3 mt-md-0 4mb-6">{{ $t("posts.sidebar.heading") }}</h3>
    <PostSearch :isRecipe="isRecipe" class="my-6"></PostSearch>
    <PostCategories class="my-6"></PostCategories>
    <h6 class="d-flex align-center">
      <v-icon class="mr-3">$doc</v-icon>
      {{
        isRecipe
          ? $t("posts.sidebar.latestRecipes")
          : $t("posts.sidebar.latestArticles")
      }}
    </h6>
    <LatestPost :isRecipe="isRecipe" class="my-6"></LatestPost>
    <PostSidebarWidgets></PostSidebarWidgets>
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import PostSearch from "@/components/wordpress/PostSearch.vue";
import PostCategories from "@/components/wordpress/PostCategories.vue";
import LatestPost from "@/components/wordpress/LatestPost.vue";
import PostSidebarWidgets from "@/components/wordpress/PostSidebarWidgets.vue";
export default {
  name: "PostSidebar",
  components: {
    PostSearch,
    PostCategories,
    LatestPost,
    PostSidebarWidgets
  },
  props: {
    isRecipe: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
